@import '/src/styles/shared-styles';

.home-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .text-accueil {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 600px;
    padding: 1em;

    ul {
      margin: 0.4em 0;
    }
    span {
      font-weight: 700;
      color: $color-primary;
    }

    .link {
      color: $color-secondary;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .home-title {
    margin: 1em 0;
    color: $color-primary;
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
}
