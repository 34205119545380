@import '/src/styles/shared-styles';

.info-mag {
  padding: 1em;
  position: relative;
  // min-width: 150px;
  // width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }
}
.info_mag__data {
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  background-color: $color-primary-light;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  gap: 0.25em;

  .libelle {
    font-weight: 700;
    text-transform: uppercase;
    color: $color-primary;
  }
}
