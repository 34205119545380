@import '/src/styles/shared-styles';

.import-list-form {
  padding: 1em;
  // .alert-text {
  //   color: $color-danger;
  // }
  .important {
    font-weight: 700;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  .form__body {
    .form-title {
      font-weight: 700;
      font-size: 1.1em;
      margin-bottom: 1em;
    }
    .conditions {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
  }

  .form__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    font-size: 0.85em;

    .actions-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
    }
  }
}
