@import '/src/styles/shared-styles';

.articles-rfa-table {
  position: relative;
  height: 80vh;
  width: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  width: 1025px;
  max-width: 90vw;

  .declared-row {
    background-color: rgba($color-tertiaire, 0.1);
  }

  .filters {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .search-input {
      flex: 1;
      padding: 0.5em 1em;
      border: 1px solid $color-neutral;
      border-radius: 4px;
      font-size: 1em;
      margin-right: 1em;
      outline: none;
      color: $color-text;

      &:focus {
        border-color: $color-primary;
        box-shadow: 0 0 0 2px rgba($color-primary, 0.2);
      }
    }

    .checkbox-label {
      display: flex;
      align-items: center;
      font-size: 1em;
      color: $color-text;
      position: relative;
      cursor: pointer;
      margin-left: 1em;

      .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .custom-checkbox {
        margin-right: 0.5em;
        width: 16px;
        height: 16px;
        background-color: white;
        border: 1px solid $color-neutral;
        border-radius: 3px;
        position: relative;
      }

      .checkbox-input:checked+.custom-checkbox {
        background-color: $color-primary;
        border-color: $color-primary;
      }

      .checkbox-input:checked+.custom-checkbox::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}