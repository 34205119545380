@import '/src/styles/shared-styles';

.rfa-textarea {
  height: fit-content;
  min-width: fit-content;
  // max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 1em;
  background-color: $color-primary-light;
  border-radius: 4px;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  label {
    font-weight: 700;
    color: $color-text-variant;
  }

  textarea {
    padding: 0 0 0.5em 0;
    background-color: transparent;
    color: $color-text;
    border-bottom: 1px solid $color-text;
    outline: inherit;

    &::placeholder {
      text-align: left;
    }

    &:focus {
      border-color: $color-primary;
    }
  }
  .align-right {
    text-align: right;
  }
  .error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-danger;
    font-weight: 700;
  }
}
