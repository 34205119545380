@import "/src/styles/shared-styles";

.table-overlay {
  position: relative;
  padding-top: 0.25rem;
  width: fit-content;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.green-pastel-bg {
  background-color: #e6f5ea;
}

.yellow-pastel-bg {
  background-color: #fffce6;
}

.table-overlay__head {
  .filtre {
    position: relative;
    color: $color-neutral;
    font-size: 0.75em;
    margin-bottom: 1em;
  }
  .filtre__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 0.75em;
    display: flex;
    align-items: center;
  }
  .filtre__input {
    display: flex;
    align-items: center;
    padding: 0.5em 1em 0.5em 3em;
    background-color: $color-white;
    border-radius: 4px;
    border: 2px solid rgba($color-neutral, 0.7);
    width: 240px;

    &:focus {
      outline: none;
      border-color: $color-primary;
    }
  }
  .filter-columns {
    margin-left: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 1em;
  }

  .filter-column {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 0.85em;
  }
}

.table-overlay__body {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba($color-primary, 0.2);
  border-radius: 4px;
  overflow: hidden;

  .table-wrapper {
    overflow-x: auto;

    .table {
      color: $color-text;
    }
    .table__head {
      background-color: $color-primary-variant;
      color: $color-text-variant;
      border-bottom: 1px solid rgba($color-primary, 0.3);

      .head-cell {
        position: relative;
        padding: 0.5em 2em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        background-color: $color-primary-variant;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .separated {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 2px;
          height: 70%;
          transform: translateY(-50%);
          background-color: $color-primary;
        }
      }
    }
    .table__body {
      .positive-row {
        background-color: rgba($color-tertiaire, 0.1);
      }
      .body-row {
        font-size: 0.75em;
      }
      .body-cell {
        position: relative;
        padding: 0.5em 1em;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid rgba(black, 0.2);
      }
      .action-cell {
        padding: 0.5em 0;
        background-color: $color-primary-variant;
        overflow: visible;
      }
      .border-right-cell {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -1px;
          width: 2px;
          height: 100%;
          background-color: rgba($color-primary, 0.9);
        }
      }
      .border-left-cell {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -2.5px;
          width: 2px;
          height: 100%;
          background-color: rgba($color-primary, 0.9);
        }
      }
    }
  }
  .pagination {
    position: sticky;
    background-color: rgba($color-primary-variant, 0.7);
    color: $color-text-variant;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85em;
  }

  .pagination__action {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;

    button {
      padding: 0.25em 1em;
      margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
      border: 1px solid #ddd; /* Gray */
      border-radius: 4px;
      background-color: rgba(white, 0.3);
      font-weight: 700;
      color: $color-text-variant;

      &:disabled {
        background-color: rgba(gray, 0.5);
      }
    }
    .page-indicator {
      padding: 0 0.5em;
      font-weight: 700;
    }

    select {
      padding: 0.5em 1em;
      color: $color-neutral;
      background-color: rgba($color-white, 0.8);
      border: 1px solid $color-neutral;
      border-radius: 4px;
      font-weight: 700;

      &:focus {
        outline: none;
        border-color: $color-primary;
      }
    }
  }

  .pagination__info {
    padding: 0.25em 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    display: flex;
    gap: 1em;
  }

  .center-cell {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    min-width: 40px;
    color: $color-tertiaire;
  }
}

@media screen and (max-width: 1024px) {
  .pagination {
    &__action :not(button) {
      display: none !important;
    }
  }
}
.total-articles {
  text-align: right;
  margin-bottom: 10px;
}
.head-row-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.legend {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.legend-item {
  margin-right: 10px;
  padding: 2px 5px;
  font-size: 0.9em;
}

.yellow-background {
  background-color: #fffce6; // Couleur jaune pastel utilisée précédemment
  border-bottom: 2px solid #fffacc; // Une teinte jaune plus foncée pour la bordure
}

.green-background {
  background-color: #e6f5ea; // Couleur verte pastel utilisée précédemment
  border-bottom: 2px solid #ccf2d6; // Une teinte verte plus foncée pour la bordure
}
