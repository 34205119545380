.downlist-card {
  .downlist-card__body {
    padding: 1em;
    text-align: center;
  }
  .downlist-card__action {
    display: flex;
    padding: 1em;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    font-size: 0.85em;
  }
}
