@import '/src/styles/shared-styles';

.contrats-rfa-table {
  position: relative;
  height: 80vh;
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  width: 1025px;
  max-width: 90vw;

  .declared-row {
    background-color: rgba($color-tertiaire, 0.1);
  }
}