@import "/src/styles/shared-styles";

.article {
  width: 100%;
}

.article__head {
  padding: 1em;
  text-align: center;
  h3 {
    font-size: 1.5em;
    font-weight: 700;
  }
}

.article__body {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  section {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
