@import '/src/styles/shared-styles';

.litige-drawer-overlay {
  position: relative;
  height: 100%;
  width: 100%;

  .litige-drawer {
    height: 100%;
    width: 100%;
  }
  .litige-drawer__header {
    display: flex;
    align-items: center;
    gap: 1em;
    color: $color-text-variant;
  }
}

.store-name {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__city {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 1.25rem;
      height: 0.125rem;
      background-color: currentColor;
      margin: 0 0.5rem;
    }
  }
  &__sub-info {
    margin-top: 1rem;
    padding: 0 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 0.75em;
    color: $color-text;
    font-weight: 400;

    .libelle {
      font-weight: 700;
      text-transform: capitalize;
      color: $color-primary;
    }

    .forward {
      color: $color-warning;
    }
  }
}
