@import '/src/styles/shared-styles';

.mag-manquant-form {
  position: relative;
  padding: 1em;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  background-color: rgba($color-black, 0.05);

  .form {
    display: flex;
    width: fit-content;
    flex-direction: column;
    gap: 1em;
    .title {
      font-weight: 700;
    }
  }
  .form__input-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }

  .form__action {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 0.5em;
    font-size: 0.85em;
  }
}
