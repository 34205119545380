@import '/src/styles/shared-styles';

.password-field {
  position: relative;
  width: 100%;
  margin-top: 1.5em;
  position: relative;
  display: flex;
  flex-direction: column;

  .field {
    position: relative;
    display: flex;
    flex-direction: column;
    input {
      position: relative;
      padding: 0.5em 1em;
      padding-right: 2em;

      background-color: rgba($color-white, 0.5);
      color: $color-neutral;
      border-bottom: 2px solid $color-neutral;

      &:focus {
        outline: none;
        border-color: $color-primary;
      }
    }

    label {
      position: absolute;
      top: -1.5em;
      color: $color-primary;
      font-weight: 700;
      font-size: 0.9em;
    }
  }

  .error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-danger;
    font-weight: 700;
    font-size: 0.75em;
  }
  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding-right: 0.75em;
    display: flex;
    align-items: center;
  }
}
