@import '/src/styles/shared-styles';

.footer {
  background-color: $color-primary-variant;
  color: $color-text-variant;
  font-size: 0.85em;
  height: 100%;
  width: 100%;

  .logo-wrap {
    margin: 1em;
    display: flex;
    justify-content: center;
    background-color: rgba($color-white, 0.75);
    border-radius: 9999px;
  }

  .logo {
    position: relative;
    padding: 1rem;
    height: 2.5em;
    width: 100%;
    object-fit: contain;
  }

  .copyright {
    // flex items-center justify-center pb-4 text-sm
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer__contact {
  // flex justify-evenly flex-wrap items-center m-4 p-4 mx-auto
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
}
