@import '/src/styles/shared-styles';

.form-reset {
  h3 {
    margin-bottom: 1em;
    // color: $color-primary;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.1em;
    text-align: center;
  }
  .form-reset__inputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .mail-sent-message {
    color: $color-primary;
  }
  .form-reset__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    gap: 0.5em;

    a {
      color: $color-link;
      font-weight: 700;
      font-size: 0.75em;
      &:hover {
        color: $color-link-hover;
      }
    }
  }
  .error-mail {
    color: $color-danger;
  }
}
