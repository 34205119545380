@import '/src/styles/shared-styles';

.loader-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .loarder-component__img {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 105px;
    width: 105px;

    img {
      height: 100%;
      width: 100%;
      filter: opacity(50%);
      animation: bounce 2s ease infinite;

      @keyframes bounce {
        0% {
          transform: translateY(0%);
          height: 85%;
        }

        50% {
          transform: translateY(-15%);
          height: 100%;
          // width: 5vw;
        }

        100% {
          transform: translateY(0%);
          height: 85%;
        }
      }
    }
  }
  .loader-component__rolling {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: $color-primary;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 2s linear infinite;
  }

  $color-roll: rgba($color-primary, 0.8);
  @keyframes shadowRolling {
    0% {
      box-shadow: 0px 0 rgba(255, 255, 255, 0),
        0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
        0px 0 rgba(255, 255, 255, 0);
    }
    12% {
      box-shadow: 100px 0 $color-roll, 0px 0 rgba(255, 255, 255, 0),
        0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    25% {
      box-shadow: 110px 0 $color-roll, 100px 0 $color-roll,
        0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    36% {
      box-shadow: 120px 0 $color-roll, 110px 0 $color-roll,
        100px 0 $color-roll, 0px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 130px 0 $color-roll, 120px 0 $color-roll,
        110px 0 $color-roll, 100px 0 $color-roll;
    }
    62% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 $color-roll,
        120px 0 $color-roll, 110px 0 $color-roll;
    }
    75% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0),
        200px 0 rgba(255, 255, 255, 0), 130px 0 $color-roll,
        120px 0 $color-roll;
    }
    87% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0),
        200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
        130px 0 $color-roll;
    }
    100% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0),
        200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
        200px 0 rgba(255, 255, 255, 0);
    }
  }
}
