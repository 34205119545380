@import '/src/styles/shared-styles';

.declaration-table-nf-overlay {
  position: relative;
  overflow: auto;

  .table-title {
    text-align: center;
  }

  .declaration-table-nf {
    font-size: 0.85em;
    padding: 0 1rem;
  }
}
