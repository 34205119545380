@import '/src/styles/shared-styles';

.admin-layout {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100vw;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;

  &__dashboard {
    width: 300px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
  }
  &__body {
    flex: 1;
    position: relative;
    box-sizing: border-box;
    min-height: fit-content;
    max-width: calc(100% - 300px);
  }
}

.nav-dashboard-overlay {
  padding: 1rem;

  .nav-dashboard {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-link {
      color: rgba($color-text, 0.7);
      display: flex;
      align-items: center;
      gap: 1em;
      width: fit-content;

      &:hover {
        color: $color-text;
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .admin-layout {
    &__dashboard {
      display: none;
    }
    &__body {
      max-width: 100%;
    }
  }
}
