.declaration-actions {
  margin: 0 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
  font-size: 0.85em;

  .btn-list {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
  }
}
