@import '/src/styles/shared-styles';

.declaration-table-overlay {
  position: relative;
  overflow: auto;

  .declaration-table {
    font-size: 0.85em;
    padding: 0 1rem;
  }

  .uncheck-all-btn {
    color: $color-tertiaire;
    font-size: 0.85em;
  }
  .text-right {
    text-align: right;
  }
}
