@import '/src/styles/shared-styles';

.litiges-table-overlay {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .litiges-table {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    overflow: auto;

    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }
}
