@import '/src/styles/shared-styles';

$background-color: transparent;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  padding: 1em;
  position: absolute;
  top: 0;
  min-width: 350px;
  min-height: 350px;
  max-width: 85vw;
  max-height: 85vh;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  background-color: $background-color;
  overflow-y: auto;
}

.modal__head {
  position: relative;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid currentColor;

  .modal-title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.15em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75em;
  }

  .modal-exit {
    display: flex;
    align-items: center;
  }
}

.modal__body {
  position: relative;
  box-sizing: border-box;
  top: 0;
  margin-top: 1em;
  width: 100%;
  max-height: 100%;
  border: 2px solid currentColor;
  overflow: auto;
}

@mixin color-modal($color, $color-background) {
  // color: $color;
  .modal__head {
    background-color: $color-background;
    border-color: $color;
  }
  .modal__body {
    background-color: $color-background;
    border-color: $color;
  }

  .modal-exit {
    color: $color;
  }
}

.color-primary {
  @include color-modal($color-primary, $color-white);
}
.primary-modal-bis {
  @include color-modal($color-tertiaire, $color-white);
}
.primary-transparent-modal {
  color: $color-primary;

  .modal__head {
    background-color: $color-primary-variant;
  }
  .modal__body {
    border: none;
  }
}
.secondary-modal {
  @include color-modal($color-white, $color-primary);
}
.danger-modal {
  @include color-modal($color-danger, $color-white);
}
.warning-modal {
  @include color-modal($color-warning, $color-white);
}
