@import '/src/styles/shared-styles';

.missing-data-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.missing-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;

  svg {
    opacity: 0.75;
  }

  h4 {
    font-size: 1.5rem;
  }
}
