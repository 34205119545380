@import '/src/styles/shared-styles';

.declaration {
  // h-full w-full bg-white z-10 pt-16
  width: 100%;
}

.declaration__head {
  padding: 1em;
  text-align: center;
  text-transform: uppercase;

  h3 {
    font-size: 1.5em;
    // color: $color-primary;
    font-weight: 700;
  }
}

.declaration__body {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  section {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
