@import "/src/styles/shared-styles";

.dashboard-rfa-screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .dashboard-rfa {
    margin-top: 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.85em;
  }

  .dashboard-rfa__head {
    padding: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      font-size: 1.5em;
      color: $color-primary;
      font-weight: 700;
    }

    .action {
      margin: 1em 0em;
      display: flex;
      justify-content: flex-end;
    }
  }
}