@import '/src/styles/shared-styles';

.info-declaration-modal {
  padding: 1em 2em;
  font-size: 0.85rem;

  .title {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0.5em;
    font-size: 1rem;
    font-weight: 700;
    padding-left: 25px;

    span {
      position: absolute;
      left: 0;
    }
  }

  .forward {
    font-weight: 700;
  }

  ul:not(.forward) {
    color: $color-text;
  }

  ul {
    margin-left: 1em;
  }
}

.info-declaration-modal__body {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
