@import '/src/styles/shared-styles';

.dashboard-fournisseur-screen {
  height: 100%;
  width: 100%;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .dashboard-fournisseur {
    margin-top: 1em;
    padding: 1em;
    // min-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.85em;
    // background-color: red;
  }
  .dashboard-fournisseur__head {
    padding: 1em;
    display: flex;
    flex-direction: column;
    .title {
      text-transform: uppercase;
      font-size: 1.5em;
      color: $color-primary;
      font-weight: 700;
    }
  }
}
