@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=System+UI&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&display=swap');
/**
Couleurs: couleurs de base utilisées dans votre application, telles que la couleur primaire, la couleur secondaire, les couleurs de fond, les couleurs de texte, etc.
Tailles de police: tailles de police couramment utilisées dans votre application, telles que la taille de police de base, la taille de police des titres, etc.
Espacements: espacements couramment utilisés dans votre application, tels que les marges, les paddings, etc.
Animations: délais d'animation couramment utilisés dans votre application.
Breakpoints de responsivité: points de rupture utilisés pour les styles de responsivité, tels que la largeur minimale de l'écran à laquelle les styles de média mobile s'appliquent.
*/

// Couleurs
// $color-primary: #c97434;
// $color-primary: #ebe5e1;
// $color-primary-variant: #f8ece3;
// $color-primary-light: #f8ece3;
// $color-secondary: #37734c;
// $color-nav: #ebe5e1;
// inedis green : #bfc734
// $color-excel: #438f5d;
// $color-excel-variant: #daebe0;
$color-danger: #dc143c;
$color-warning: #ff5b00;
$color-white: #ffffff;
$color-black: #000;
$color-neutral: #6b7280;
// $color-disabled: #bac0cc;
// $color-text: $color-black;

// ----------- color --------------
$color-primary: #ae642d;
$color-primary-light: #fff;
$color-primary-variant: #ebe5e1;
$color-secondary: #ccc5b9;
$color-tertiaire: #64954e;
$color-tertiaire-hover: #7aae63;
$color-link: #8492bf;
$color-link-hover: #404e7c;
$color-text: #2b180a;
$color-text-variant: $color-primary;
$color-text-secondary: #72421d;

// Specific color btn
$color-color-danger: #7c0b2b;

// Polices d'écriture
$font-family: 'Lato', sans-serif;
$font-family-title: 'Domine', serif;

// Exportation pour le JS
:export {
  colorText: $color-text;
  colorTextVariant: $color-text-variant;
  colorPrimaryVariant: $color-primary-variant;
  colorPrimary: $color-primary;
  colorNeutral: $color-neutral;
}
