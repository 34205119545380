@import '/src/styles/shared-styles';

.contrat-select {
  position: relative;
  height: fit-content;
  width: fit-content;
  padding: 1em;
  background-color: rgba($color-white, 0.7);
  border-radius: 4px;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  max-width: 100%;

  label {
    font-weight: 700;
    color: $color-text-variant;
  }

  select {
    background-color: transparent;
    color: $color-neutral;
    border-bottom: 1px solid $color-neutral;
    outline: inherit;
    border: none;
    cursor: pointer;

    &:focus {
      border-color: $color-primary;
    }
  }
  .number {
    text-align: right;
  }
  .error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-danger;
    font-weight: 700;
  }
}
