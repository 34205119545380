@import '/src/styles/shared-styles';

.litige-wrap {
  .litige {
    width: 100%;
  }
  .litige__head {
    padding: 1em;
    margin-bottom: 4em;
    text-align: center;
    text-transform: uppercase;

    h3 {
      font-size: 1.5em;
      // color: $color-primary;
      font-weight: 700;
    }
    .aide-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2em;
      text-transform: none;

      .intro-litige {
        margin: 1em;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1em;
        text-align: start;
        min-width: 250px;
        span {
          font-weight: 700;
          color: $color-text-variant;
        }

        .text-icon {
          position: relative;
          color: $color-tertiaire;
          svg {
            position: relative;
            top: 5px;
            left: 0;
          }
        }
      }

      .legende {
        width: 150px;
      }
    }
  }

  .litige__body {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
