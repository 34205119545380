@import '/src/styles/shared-styles';

.total-declare-table {
  position: relative;
  padding: 2em;
  overflow-x: auto;
  max-width: 100%;
  font-size: 0.65em;

  .table {
    width: fit-content;
    height: fit-content;
    background-color: $color-white;
  }
  .table__head {
    background-color: $color-primary;
    color: $color-white;
    text-transform: uppercase;

    .cell-head {
      padding: 1em 2em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: 1px solid $color-primary-variant;
      text-align: center;
    }
  }
  .table__body {
    .cell-body {
      padding: 1em 2em;
      text-align: center;
      border-right: 1px solid $color-primary-variant;
    }
  }
}
