@import '/src/styles/shared-styles';

.alert-import-modal {
  max-width: 640px;

  .title {
    margin: 0.5em;
    font-weight: 700;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    color: $color-tertiaire;
  }
  .forward {
    font-weight: 700;
  }
}
