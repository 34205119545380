@import '/src/styles/shared-styles';

.alert-modal {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.alert-modal__title {
  font-weight: 700;
  font-size: 1.1em;
}
.alert-modal__body {
  color: $color-text;
  //   font-weight: 700;
}
.alert-modal__action {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  gap: 0.5em;
  font-size: 0.85em;
}
