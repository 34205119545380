.declaration-alert-cloture {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.declaration-alert-cloture__title {
  font-weight: 700;
  font-size: 1.1em;
}
.declaration-alert-cloture__action {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  gap: 0.5em;
  font-size: 0.85em;
}
