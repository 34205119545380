@import '/src/styles/shared-styles';

.selection-contrat-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .selection-contrat {
    position: relative;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    border-radius: 4px;
    background-color: rgba($color-black, 0.05);
    overflow: hidden;
    max-width: 100%;
  }

  h3 {
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 700;
  }

  button {
    margin-top: 2rem;
  }
}
