@import '/src/styles/shared-styles';

.dashboard-litige-overlay {
  padding: 1rem;
  min-height: 100%;
  height: fit-content;

  .dashboard-litige {
    display: flex;
    flex-direction: column;

    &__header {
      padding: 1em;
      display: flex;
      flex-direction: column;
      .title {
        text-align: center;
        text-transform: uppercase;
        font-size: 1.5em;
        color: $color-primary;
        font-weight: 700;
      }
    }
    &__table {
      display: flex;
      justify-content: center;
    }
  }
}


