$footer-size: 150px;
.app-layout {
  position: relative;
  min-height: calc(100vh + $footer-size);
  width: 100vw;
  height: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0 1fr $footer-size;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'app-layout__nav'
    'app-layout__body'
    'app-layout__footer';
}

.app-layout__nav {
  grid-area: app-layout__nav;
}

.app-layout__body {
  position: relative;
  box-sizing: border-box;
  padding: 4rem 0 0 0;
  grid-area: app-layout__body;
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: fit-content;
}

.app-layout__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 350px;
    filter: opacity(5%);
  }
}

.app-layout__footer {
  grid-area: app-layout__footer;
}
