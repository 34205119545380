@import '/src/styles/shared-styles';

.litige-form {
  position: relative;
  height: 100%;
  background-color: rgba($color-black, 0.05);
  // padding: 1em;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  box-sizing: border-box;
  overflow: auto;

  .title {
    color: $color-text-secondary;
  }

  h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
    color: $color-text-secondary;

    &::before,
    &::after {
      content: '';
      flex: 1;
      display: block;
      width: 1.25rem;
      max-width: 20%;
      height: 0.125rem;
      background-color: currentColor;
      margin: 0 0.5rem;
    }
  }

  .inline-inputs {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;

    &:first-child {
      flex: 1 0;
    }
  }

  .depots-alert {
    position: relative;
    text-align: center;

    div {
      font-size: 1em;
      text-align: center;
      color: $color-warning;
      text-transform: uppercase;
      text-overflow: ellipsis;
      span {
        font-weight: 700;
      }
    }
  }

  .info-litige-overlay {
    position: relative;
    padding: 1em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4rem;
    background-color: $color-secondary;
    align-items: stretch; /* Ajout de l'alignement */

    .action-litige {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 1em;

      .action {
        button {
          width: 100%;
          height: 100%;
          max-height: inherit;
          max-width: 250px;
          font-size: 1em;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .or-separator {
        display: flex;
        align-items: center;
        color: $color-text-secondary;
        font-weight: 700;
      }
    }

    .other-amount {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    .info-litige {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 0.5em;
    }

    .info-litige__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .info {
      width: 100%;
      background-color: $color-primary-light;
      border-radius: 4px;
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      p {
        padding: 0.25em 0.5em;
        width: 100%;
        text-align: center;
      }

      span {
        font-weight: 700;
        text-transform: uppercase;
        color: $color-text-variant;
      }
    }
  }
}
.litige-form__head {
  padding: 2rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: -moz-fit-content;
  width: fit-content;
  background-color: $color-secondary;
  width: 100%;
  min-height: fit-content;

  div {
    display: flex;
    gap: 1em;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  button {
    font-size: 0.85em;
    color: $color-tertiaire;
    &:hover {
      color: $color-tertiaire-hover;
    }
  }
}

.litige-form__body {
  position: relative;
  padding: 1em;
  // z-index: 1;
  // color: #fff;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.litige-form__foot {
  width: 100%;
  padding-bottom: 1rem;
  flex-grow: 1;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;

  p {
    font-weight: 700;
    color: $color-warning;
  }

  .inline-btn {
    position: relative;
    padding: 1em 0 0 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
}

.litige-form__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  img {
    height: 300px;
    filter: opacity(10%);
  }
}

@media screen and (max-width: 1024px) {
  .or-separator {
    display: none !important;
  }
}
