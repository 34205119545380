@import '/src/styles/shared-styles';

.aide-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  .aide {
    display: flex;
    flex-direction: column;
  }

  .aide-title {
    color: $color-primary;
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  .section {
    max-width: 700px;
    ul {
      margin: -0.4em 0 0 0;
    }
    span {
      font-weight: 700;
      color: $color-primary;
    }
    .btn-img {
      max-width: 150px;
    }
  }

  .section-body {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .section-title {
    margin-bottom: 0.5em;
    font-weight: 700;
    font-size: 1.1em;
    color: $color-primary;
  }
}
.aide-navigation {
  display: flex;
  gap: 20px;
  justify-content: center; 
  align-items: center;

  button {
    padding: 10px 20px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;

    &.active {
      background-color: #64954e;
      color: #fff;
    }
  }
}
.aide-content {
  margin-top: 40px;
}


.aide-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.aide-table th,
.aide-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.aide-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}
