@import '/src/styles/shared-styles';

.drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;

  .drawer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 320px;
    width: fit-content;
    max-width: 900px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    &.left-side {
      left: 0;
    }

    &-header {
      position: sticky;
      display: flex;
      align-items: center;
      gap: 1em;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      color: $color-text-secondary;

      h3 {
        flex: 1;
        text-align: center;
        text-transform: uppercase;
        color: $color-text-secondary;
      }
    }

    &-content {
      flex: 1;
      overflow-y: auto;
    }

    .arrow-slide-icon {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &.right-side {
        right: 0.5rem;
      }

      &.left-side {
        left: 0.5rem;
      }
    }
  }
}
