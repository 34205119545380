@import '/src/styles/shared-styles';

.declaration-form {
  position: relative;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;

  h5 {
    font-weight: 700;
  }

  .title {
    font-weight: 700;
  }

  .depots-alert {
    position: relative;
    text-align: center;

    div {
      font-size: 1em;
      text-align: center;
      color: $color-warning;
      text-transform: uppercase;
      text-overflow: ellipsis;
      span {
        font-weight: 700;
      }
    }
  }
}
.declaration-form__body {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inputs-obligation {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
  .inputs-optionnal {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
}
.declaration-form__foot {
  position: relative;
  flex-grow: 1;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1em;

  p {
    font-weight: 700;
    color: $color-warning;
  }
}

.declaration-form__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  img {
    height: 300px;
    filter: opacity(10%);
  }
}
