@import "/src/styles/shared-styles";

.fournisseurs-table {
  position: relative;
  height: 80vh;
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  width: 1200px;
  max-width: 90vw;

  .add-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .declared-row {
    background-color: rgba($color-tertiaire, 0.1);
  }

  .MuiDataGrid-root {
    color: $color-text;

    .MuiDataGrid-toolbarContainer {
      background-color: rgba($color-primary, 0.2);
    }

    .MuiDataGrid-columnHeaders {
      background-color: $color-primary-variant;
      color: $color-text-variant;
      border-bottom: 1px solid rgba($color-primary, 0.3);
      border-radius: 0px;

      .MuiSvgIcon-root {
        color: $color-primary;
      }
    }

    .custom-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .email-cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .MuiDataGrid-cell {
      border: 1px solid rgba($color-neutral, 0.1);
      font-size: 0.8em;
      font-weight: 400;
    }

    .MuiDataGrid-footerContainer {
      background-color: rgba($color-primary-variant, 0.7);
      color: $color-text-variant;

      .MuiButtonBase-root,
      .MuiSvgIcon-root {
        color: $color-text-variant;
      }

      .MuiButtonBase-root:hover {
        background-color: $color-white;
        color: $color-primary;
      }

      .MuiSvgIcon-root:hover {
        color: $color-primary;
      }

      .MuiDataGrid-selectedRowCount {
        display: none;
      }
    }

    .MuiTablePagination-root,
    .MuiPaginationItem-root {
      color: $color-white;
    }

    .Mui-selected {
      background-color: $color-primary;
    }
  }
}