@import '/src/styles/shared-styles';

.notification-overlay {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  transition: all 5s ease;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification {
  position: fixed;
  top: 60px;
  width: fit-content;
  // max-width: 300px;
  padding: 2em 4em;
  border-radius: 4px;
  background-color: $color-white;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  animation: fade 4s linear forwards;
  &.success{
    color: $color-tertiaire;
  }
  &.error{
    color: $color-danger;
  }
  p {
    white-space: nowrap;
  }
}

@keyframes fade {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
  90% {
    opacity: 1;
    transform: translateY(0px);
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

.progress {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 0;
  height: 3px;
  
  border-radius: 4px;
  animation: progress 3s 0.25s linear forwards;
  &.success{
    background-image: linear-gradient(
      to right,
      rgba($color-tertiaire, 0.5),
      $color-tertiaire
    );
  }
  &.error{
    background-image: linear-gradient(
      to right,
      rgba($color-danger, 0.5),
      $color-danger
    );
  }
}

@keyframes progress {
  to {
    width: calc(100% - 10px);
  }
}
