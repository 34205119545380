@import '/src/styles/shared-styles';

//Colors variables
$color-bg-nav: $color-primary-variant;
$color-link: $color-text-variant;
$color-link-hover: $color-primary-variant;

//sizes variables
$nav-height: 4rem;

.nav__logo {
  margin: 0 2em;
  position: relative;
  height: 40px;
  width: 40px;
}

.nav {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-bg-nav;
  height: $nav-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-primary;
  // font-weight: 700;
  border-bottom: 1px solid rgba($color-black, 0.1);

  &__logo {
    position: relative;
    margin: 0 2em;
    height: 40px;
    width: 40px;
  }

  &__links,
  &__actions {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__burger {
    display: none;
  }

  .sub-dropdown {
    position: absolute;
    display: none;
    min-width: fit-content;
    z-index: 10;
    background-color: $color-bg-nav;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 3.75rem;
    left: 0;
    right: 0;
    text-align: center;

    li {
      display: flex;
      align-items: center;
    }

    &.show {
      display: block;
    }
  }

  .dropdown {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
      padding: 1em;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .dropdown-title {
        min-width: 100px;
      }
    }
  }

  .link {
    position: relative;
    text-decoration: none;
    color: $color-link;
    font-weight: 700;
    min-width: 100px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      height: 3px;
      width: 0px;
      border-radius: 2px;
      background-color: rgba($color-primary, 0.5);
      transition: 0.4s ease;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .forward-link {
    color: $color-tertiaire;

    &::after {
      background-color: rgba($color-tertiaire, 0.5);
    }
  }
}

/* -------------------------------- SMALL DEVICES -------------------------------- */
/* Au niveau le plus haut du code */
@media screen and (max-width: 1024px) {
  .nav__burger {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    min-height: $nav-height;
    width: 100%;
    padding: 0 1rem;
  }
  .nav__links,
  .nav__actions {
    display: none;
  }
}
