@import "/src/styles/shared-styles";

.article-table-overlay {
  position: relative;
  overflow: auto;

  .article-table {
    font-size: 0.85em;
    padding: 0 1rem;
  }

  .uncheck-all-btn {
    color: $color-tertiaire;
    font-size: 0.85em;
  }

  .filter-buttons {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 20px;
    align-items: center;
  }

  .filter-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
  }

  .filter-checkbox input[type="checkbox"] {
    margin-right: 5px;
  }

  .no-data-message {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #666;
  }

  .status-badge {
    display: inline-flex;
    align-items: center;
    padding: 1px 2px;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
  }

  .status-badge.deleted {
    background-color: #d9534f;
  }

  .status-badge.updated {
    background-color: #f0ad4e;
  }

  .status-badge.created {
    background-color: #5cb85c;
  }

  .status-badge {
    background-color: #777;
  }


}