@import '/src/styles/shared-styles';

$color-bg: rgba($color-primary-variant, 0.9);
$color-link: $color-white;
$color-link-hover: $color-primary-variant;
$ham-max-width: 250px;
$nav-height: 4rem;

.hamburger__container {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.hamburger__container button {
  z-index: 10;
  cursor: pointer;
}

.menuHam {
  margin-top: $nav-height;
  background-color: $color-bg;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 250px;
  z-index: 9;
}
.list {
  position: relative;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1em;

  li {
    padding: 0.5rem 2rem;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child {
      margin-top: 3rem;
    }
  }
}

.link {
  position: relative;
  text-decoration: none;
  color: $color-link;
  font-weight: 700;
  min-width: 100px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 3px;
    width: 0px;
    border-radius: 2px;
    background-color: $color-link-hover;
    transition: 0.4s ease;
  }

  &:hover::after {
    width: 100%;
  }
}

.menuHam.showMenu {
  width: 100%;
}
