@import '/src/styles/shared-styles';

.drawer-dashboard-overlay {
  position: relative;
  top: 0;
  width: 300px;
  height: 100%;

  .drawer-dashboard {
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    min-width: fit-content;
    background-color: white;
    box-shadow: 0 -1rem 1rem rgba(0, 0, 0, 0.3);
    // transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    &.left-side {
      left: 0;
    }

    &-header {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 60px;
      gap: 1em;
      padding: 1rem 0;
      border-bottom: 1px solid #ccc;
      color: $color-text-secondary;

      h3 {
        position: relative;
        flex: 1;
        text-align: center;
        text-transform: uppercase;
        color: $color-text-secondary;
      }
    }

    &-content {
      flex: 1;
    }
    &-close {
      position: absolute;
      top: 0;
      right: 0.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      .btn {
        padding: 0.25em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: solid 1px transparent;

        &:hover {
          border-color: $color-primary;
        }
      }
    }
  }
}
