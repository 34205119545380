@import '/src/styles/shared-styles';

.declaration-modal-wrapper {
  position: relative;
}
.declaration-modal {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: max-content;
  max-width: fit-content;
}

.declaration-modal__details {
  position: relative;
  background-color: $color-secondary;
  flex: 1;
  h4 {
    color: $color-text-secondary;
  }
}
.declaration-modal__form {
  position: relative;
  flex: 1 0 300px;
  align-items: stretch;
  max-height: 100%;
  overflow: auto;
  background-color: rgba($color-black, 0.05);
}

.details-declaration {
  width: 300px;
}
