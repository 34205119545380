@import '/src/styles/shared-styles';

.basic-input {
  margin-top: 1.5em;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    // width: 100%;
    padding: 0.5em 1em;
    background-color: rgba($color-white, 0.5);
    color: $color-neutral;
    border-bottom: 2px solid $color-neutral;

    &:focus {
      outline: none;
      border-color: $color-primary;
    }
  }

  label {
    position: absolute;
    top: -1.5em;
    color: $color-primary;
    font-weight: 700;
    font-size: 0.9em;
  }
  .error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-danger;
    font-weight: 700;
    font-size: 0.75em;
  }
}
