@import "/src/styles/shared-styles";

.adduser-modal-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: fit-content;
  width: 100%;

  .adduser-modal-bg {
    position: relative;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    flex: 1 0 150px;
    width: 100%;
    background-color: rgba($color-white, 0.2);

    .adduser-mail-modal-icon {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        position: relative;
        margin-top: -1rem;
      }
    }
  }

  .adduser-modal {
    position: relative;
    margin-top: -1rem;
    flex: 1;
    max-width: 100%;
    background-color: $color-primary-variant;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    flex-direction: column;
  }

  .adduser-modal__title {
    padding: 0.5em;
    font-weight: 400;
    font-size: 1.25em;
    text-transform: uppercase;
    border-radius: 20px;
    text-align: center;
    color: $color-text-variant;
  }
  .adduser-modal__content {
    position: relative;
    padding: 0 1em;
    height: fit-content;
    flex-grow: 1;
    max-width: 100%;
    text-align: center;
    .adduser-label{
      padding: 10px;
    }
    input {
      width: 80%;
      padding: 10px; // Ajoutez du padding pour que l'input soit plus grand
      border: 1px solid #ccc; // Ajoutez une bordure
      margin-bottom: 10px; // Ajoutez de la marge en dessous
    }
  }
  .adduser-modal__action {
    margin-top: 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;

    div {
      display: flex;
      justify-content: center;
      gap: 0.5em;
    }

    p {
      color: $color-danger;
    }
  }
}
