@import './styles/shared-styles.scss';

/* Polices d'écriture */
body {
  font-size: 16px;
  line-height: 1.5;
  color: $color-text;
}

* {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

input {
  all: initial;
  font-family: $font-family;
}

button {
  background-color: transparent;
  border: none;
}

table {
  border-collapse: collapse;
}

select {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
}

textarea {
  resize: none;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 700;
  color: $color-text-variant;
}

/* Styles de lien */
a {
  text-decoration: none;
}
