@import '/src/styles/shared-styles';

.auth-layout-overlay {
  min-height: 100vh;
  background-color: $color-primary-variant;

  .auth-layout {
    position: fixed;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .auth-layout__head {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      height: 5em;
      margin-bottom: 0.5em;
    }
    .logo__text {
      color: $color-text-secondary;
      font-size: 1.5em;
      font-weight: 400;
    }
  }

  .auth-layout__body {
    min-width: 250px;
    padding: 1em 2em;
    background-color: $color-white;
    border-radius: 4px;
  }
}
