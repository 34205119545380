.spin-loader-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .loader {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 12px 0 #fff, -12px 0 #fff;
    animation: flash 1s ease-out infinite alternate;
  }

  @keyframes flash {
    0% {
      background-color: #fff2;
      box-shadow: 12px 0 #fff, -12px 0 #fff;
    }
    50% {
      background-color: #fff;
      box-shadow: 12px 0 #fff, -12px 0 #fff;
    }
    100% {
      background-color: #fff2;
      box-shadow: 12px 0 #fff, -12px 0 #fff;
    }
  }
}
